@import '@pushdr/stylekit/scss/stylekit.scss';

// overrides
$listview-edit-icon-size: 25px;

.o-input__input[type='radio'] + .o-input__label:before {
  border: initial;
  background-color: initial;
  background-image: url('/assets/icons/radiounselected.svg');
  background-size: contain;
}

.o-input__input[type='radio']:checked + .o-input__label:before {
  border: initial;
  background-color: initial;
  background-image: url('/assets/icons/radioselected.svg');
  background-size: contain;
}

.o-input__input[type='radio'] + .o-input__label:after {
  display: none;
}

.c-btn {
  max-width: 100%;
}

.c-listview {
  padding-left: 0;
}

.c-listview__item {
  padding-left: 0;
  margin-bottom: 0;
  padding-top: $base-spacing-unit--tiny;
  padding-bottom: $base-spacing-unit--tiny;
  border-bottom: 1px solid theme-color(grey-200);
  &:before {
    display: none;
  }

  &:first-child {
    border-top: 1px solid theme-color(grey-200);
  }

  > h2,
  > p {
    padding-left: $base-spacing-unit--tiny;
  }
}

.c-listview__item {
  .c-listview--booking & {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: $base-spacing-unit--tiny;
    padding-right: $base-spacing-unit--tiny;
    padding-top: $base-spacing-unit--small;
    padding-bottom: $base-spacing-unit--small;
    box-shadow: 0px 1px 16px theme-color(grey-200);
    margin-bottom: $base-spacing-unit--tiny;
    border-radius: 3px;
    border: none;

    &:after {
      height: auto;
      width: auto;
      background-image: none;
      background-size: auto;
      transform: none;
      color: theme-color(primary);
      font-weight: $font-weight-normal;
    }

    &.c-listview__item--select {
      &:after {
        content: 'Select';
      }
    }

    &.c-listview__item--book {
      &:after {
        content: 'Book';
      }
    }

    &:hover {
      cursor: pointer;
      background-color: theme-color(grey-200);
      transition: background-color 100ms ease-out;
    }

    transition: background-color 100ms ease-in;
  }
}

.c-listview__heading {
  font-weight: $font-weight-normal;
  margin: 0;
  padding-left: 0;
}

.c-jumbotron {
  background: theme-color(grey-300);
  border-radius: $c-form-part-border-radius;
  padding: $base-spacing-unit--tiny;
  margin: $base-spacing-unit--tiny 0;

  button.c-btn.c-btn--primary {
    margin-bottom: 0;
  }
}

h5 {
  margin: 0 0 $base-spacing-unit--tiny;
  color: #31393c;
}

.c-form-part__title {
  outline: none;
}

.u-sr-only {
  outline: none;
}

.u-justify-content-center {
  justify-content: center;
}

video {
  width: 100%;
}

/*fix for opentok labels and menu items*/
.OT_bar.OT_edge-bar-item.OT_mode-auto,
button.OT_edge-bar-item.OT_mute.OT_mode-auto,
.OT_edge-bar-item.OT_name .video .labelContainer .guest {
  display: none !important;
}

.videoWrapper .audioContainer {
  opacity: 0;
}

/*Modifier Classes*/
.clickable {
  cursor: pointer;
}

#video-background {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 150%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  max-width: unset;
}

.carousel {
  color: white !important;
  justify-content: center !important;
  .carousel-text {
    margin: 0 auto !important;
    .header {
      font-size: $font-size-h1;
      line-height: $line-height-h1;
      margin: $margin-bottom-h1;
      @include breakpoint(lg) {
        margin: $margin-bottom-h1-lg;
        font-size: $font-size-h1-lg;
        line-height: $line-height-h1-lg;
      }
    }
  }
}

.carousel {
  &-text {
    color: white;
  }
  width: 100% !important;
  position: inherit !important;
  height: 100% !important;
  background-color: transparent !important;
  border-top: none !important;
  top: 0 !important;
  bottom: auto !important;
  padding: 15px !important;
  box-sizing: border-box !important;

  p.waiting-text,
  p {
    color: white;
    font-size: $font-size-h3;
    line-height: $line-height-h3;
    margin: $margin-bottom-h3;
    @include breakpoint(lg) {
      margin: $margin-bottom-h3-lg;
      font-size: $font-size-h3-lg;
      line-height: $line-height-h3-lg;
    }
  }

  .waiting-text {
    margin-bottom: 1.5em;
  }

  .btn.btn-notify {
    color: $grey-onyx;
    background-color: white;
    max-width: 310px;
  }

  h5 {
    color: white;
  }
}

.carousel {
  color: white !important;
  justify-content: center !important;
  .carousel-text {
    margin: 0 auto !important;
    .header {
      font-size: $font-size-h1;
      line-height: $line-height-h1;
      margin: $margin-bottom-h1;
      @include breakpoint(lg) {
        margin: $margin-bottom-h1-lg;
        font-size: $font-size-h1-lg;
        line-height: $line-height-h1-lg;
      }
    }
  }
}

.waiting-timepos {
  color: #ffffff;
  display: block;
  font-weight: bold;
  line-height: 1.5em;
  text-align: center;
  width: 1.6em;
  border: 5px solid white;
  font-size: 7em;
  border-radius: 50%;
  box-sizing: content-box;
  padding: 0px;
  margin: 20px auto;
  span {
    margin-top: 14px;
    display: block;
  }
}
